import React, { useEffect, useState } from "react";
import MoneyWheelBsc from '../module/index'
import { AbiItem } from 'web3-utils'
import useWeb3 from '../hooks/useWeb3'
import { ContractOptions } from 'web3-eth-contract'
import { useTokenBalance } from '../hooks/useTokenBalance'
import { getFullDisplayBalance } from '../utils/formatBalance'
import wheel from '../config/abi/wheel.json'
import erc20 from '../config/abi/erc20.json'
import { useWeb3React } from '@web3-react/core'

const MoneyWheelPlaceHolder: React.FC = () => {
    const { account } = useWeb3React()
    const wheelContractAddress = "0xe5826633b162d7A7dF32d322240693611A38b72B"
    const tokenContractAddress = "0xCf6d376126142E4C640e4D6CCd4F7d6f33ec30f8"

    const useContract = (abi: AbiItem, address: string, contractOptions?: ContractOptions) => {
        const web3 = useWeb3()
        const [contract, setContract] = useState(new web3.eth.Contract(abi, address, contractOptions))
      
        useEffect(() => {
          setContract(new web3.eth.Contract(abi, address, contractOptions))
        }, [abi, address, contractOptions, web3])
  
        return contract
    }

    const useERC20 = (address: string) => {
        const erc20Abi = (erc20 as unknown) as AbiItem
        return useContract(erc20Abi, address)
      }

    const useToken = () => {
        return useERC20(tokenContractAddress)
      }

    const useMoneyWheel = () => {
        const abi = (wheel as unknown) as AbiItem
        return useContract(abi, wheelContractAddress)
    }

    const tokenBalance = useTokenBalance(useToken())

    return (
      <MoneyWheelBsc
          contract={useMoneyWheel()}
          token={useToken()}
          account={account}
          tokenBalance={getFullDisplayBalance(tokenBalance)}
          tokenName="WHEEL"
          tokenImageUrl="https://demo.defifusion.io/defi-token.png"
          soundUrl="https://demo.defifusion.io/wheel.mp3"
          wheelBackgroundImageUrl="https://demo.defifusion.io/wheel-background.png"
          colors={{
            backgroundColor: "#181134",
            primaryColor: "#701df8",
            primaryContrastTextColor: "#fff",
            secondaryColor: "#701df8",
            secondaryContrastTextColor: "#fff",
            cardColor: "#221b3d",
            wheelPrimaryColor: "#510af9",
            wheelSecondaryColor: "#490bcb",
            jackpotColor: "#7547a2"
          }}
          demo={true}
          />
    )
}

export default MoneyWheelPlaceHolder
