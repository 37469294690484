import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import './index.css';
import App from './App';
import { Web3ReactProvider } from '@web3-react/core'
import { getLibrary } from './utils/web3React'
import { RefreshContextProvider } from './contexts/RefreshContext'

const themeDark = createMuiTheme({
  palette: {
    primary: {
      main: "#701df8",
      contrastText: "fff"
    },
    secondary: {
      main: "#0a021e",
      contrastText: "fff",
    },
    background: {
      default: "#181134",
      paper: "#221b3d",
    },
    text: {
      primary: "#fff"
    },
    divider: "rgba(255,255,255, 0.15)"
  },
  typography: {
    allVariants: {
      color: "#fff"
    },
  },
  overrides: {
    MuiButton: {
      label: {
        color: "#fff",
      },
    },
  }
});

ReactDOM.render(
  <React.StrictMode>
      <Web3ReactProvider getLibrary={getLibrary}>
        <CssBaseline>
          <MuiThemeProvider theme={themeDark}> 
            <RefreshContextProvider>
              <App/>
            </RefreshContextProvider>
          </MuiThemeProvider>
        </CssBaseline>
      </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
