import React from 'react';
import { Router, Link, Route, Switch } from 'react-router-dom'
import clsx from 'clsx';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider'; 
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CasinoIcon from '@material-ui/icons/Casino'
import TelegramIcon from '@material-ui/icons/Telegram';
import Modal from '@material-ui/core/Modal';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import useAuth from '../../hooks/useAuth'
import MoneyWheelPlaceHolder from '../../components/MoneyWheelPlaceholder';
// import CoinFlipPlaceholder from '../../components/CoinFlipPlaceholder';
import { useWeb3React } from '@web3-react/core'
import history from './routerHistory'

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      backgroundColor: "#0a021e",
      minHeight: 74,
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      backgroundColor: "#0a021e",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      backgroundColor: "#0a021e",
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
      backgroundColor: "#0a021e",
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(4),
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: "#221b3d",
    },
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: "#221b3d",
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    button: {
      margin: theme.spacing(1),
    },
    logo:{
      maxWidth: 200,
      flexWrap: 'nowrap',
      flex: 1,
      paddingTop: 10,
    },
  }),
);

export enum ConnectorNames {
  Injected = "injected",
  // WalletConnect = "walletconnect",
  // BSC = "bsc",
}

const Menu: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const { login, logout } = useAuth()
  const {account } = useWeb3React()
  const accountEllipsis = account ? `${account.substring(0, 4)}...${account.substring(account.length - 4)}` : null;
  
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const onConnect = () => {
    login(ConnectorNames.Injected);
    window.localStorage.setItem("connectorId", "injected");
    handleClose()
  };

  const disconnect = () => {
    logout()
  };

  const body = (
    <div className={classes.paper}>
      <h2 id="simple-modal-title">Connect to a wallet</h2>
      <p id="simple-modal-description">
        <div>
          <Button
              variant="contained"
              color="default"
              className={classes.button}
              startIcon={<AccountBalanceWalletIcon />}
              onClick={onConnect}>
              Metamask
          </Button>
        </div>
      </p>
    </div>
  );

  return (
    <Router history={history}>
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <img alt="logo" src="https://demo.defifusion.io/logo.png" className={classes.logo} />
          <Typography variant="h6" noWrap style={{ display: 'flex', flex: 1 }} />
          <div>
          {account ? (
              <Button color="primary" variant="contained" type="button" onClick={disconnect}>{accountEllipsis}</Button>
            ) : (
              <Button color="primary" variant="contained" type="button" onClick={handleOpen}>Connect</Button>
            )}

      <Modal
        open={modalOpen}
        className={classes.modal}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
          </div>

        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton style={{color: '#fff'}} onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List style={{ padding: 6, flex: 1}}>
          <ListItem component={Link} to="/" >
              <ListItemIcon style={{color: '#fff'}}><CasinoIcon /></ListItemIcon>
              <ListItemText style={{color: '#fff'}} primary='Money Wheel' />
          </ListItem>
          {/* <ListItem component={Link} to="/coinflip" >
              <ListItemIcon style={{color: '#fff'}}><CasinoIcon /></ListItemIcon>
              <ListItemText style={{color: '#fff'}} primary='Coinflip' />
          </ListItem> */}
        </List>
        <Divider />
        <List style={{ padding: 6}}>
          <ListItem component="a" href="https://t.me/defifusion" target="_blank" >
              <ListItemIcon style={{color: '#fff'}}><TelegramIcon /></ListItemIcon>
              <ListItemText style={{color: '#fff'}} primary='Contact' />
          </ListItem>
        </List> 
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
          <Switch>
            <Route path="/" exact>
              <MoneyWheelPlaceHolder/>
            </Route>
            {/* <Route path="/coinflip">
              <CoinFlipPlaceholder />
            </Route>  */}
          </Switch>
      </main>
    </div>
    </Router>
  );
}
export default React.memo(Menu)