import React, { useCallback, useState } from 'react'
import { Contract } from 'web3-eth-contract'
import Button from '@material-ui/core/Button';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useMoneyWheelAllowance } from './hooks/useAllowance'
import { useMoneyWheelApprove } from './hooks/useApprove'
import ConfirmBetModal from './ConfirmBetModal'

export interface MoneyWheelBet {
    val1: string
    val3: string
    val5: string
    val10: string
    val20: string
    val50: string
  }

  const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      backgroundColor: theme.palette.primary.main,
      fontWeight: 'bold',
      borderRadius: 12,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
      "&:disabled":{
        opacity: 0.8
      }
    }
  }),
);
  
interface BetActionsProps {
    bet: MoneyWheelBet
    onResult: (spin: number, reward: string) => void
    tokenContract: Contract
    tokenName: string
    moneyWheelContract: Contract
    disabled: boolean
    licensed: boolean
    account: string
  }
  
  const BetActions: React.FC<BetActionsProps> = ({ bet, onResult, tokenContract, tokenName, moneyWheelContract, disabled, licensed, account }) => {
    const classes = useStyles();
    const [requestedApproval, setRequestedApproval] = useState(false)
    const { onApprove } = useMoneyWheelApprove(tokenContract, moneyWheelContract)
    const allowance = useMoneyWheelAllowance(tokenContract, moneyWheelContract)

    // ToDo: check this function (commented code)
    const handleApprove = useCallback(async () => {
      try {
        setRequestedApproval(true)
        const txHash = await onApprove()
        // user rejected tx or didn't go thru
        if (!txHash) {
          setRequestedApproval(false)
        }
        // onPresentApprove()
      } catch (e) {
        console.error(e)
      }
    }, [onApprove])

    const renderMoneyWheelActionButtons = () => {
      if (!licensed){
        return (
          <Button fullWidth className={classes.button} variant="contained" color="primary" disabled={true}>
            Unlicensed
          </Button>
        )
      }
      else if (!allowance.toNumber()) {
        return (
          <>
            <Button fullWidth className={classes.button} variant="contained" color="primary" disabled={requestedApproval} onClick={handleApprove}>
              Approve MoneyWheel
            </Button>
          </>
        )
      }
      return (<ConfirmBetModal account={account} moneyWheelContract={moneyWheelContract} bet={bet} tokenName={tokenName} onResult={onResult} disabled={disabled} licensed={licensed} />)
    }
    return <>{renderMoneyWheelActionButtons()}</>
  }
  export default BetActions