import React from 'react';
import Menu from './widgets/Menu/Menu';
import useEagerConnect from './hooks/useEagerConnect'

const App: React.FC = () => {  
  useEagerConnect()
  
  return(
    <div>
      <Menu/>
    </div>

  )
}

export default React.memo(App)