import React, { useState, useEffect } from 'react'
import BigNumber from 'bignumber.js'
import { getFullDisplayBalance } from './utils/formatBalance'
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

interface ConfirmBetModalProps{
    openModal: boolean
    reward: string
    tokenName: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    button: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
      fontWeight: 'bold',
      borderRadius: 12,
      "&:hover": {
        backgroundColor: theme.palette.primary.main
      },
    }
  }),
);

const ConfirmBetModal: React.FC<ConfirmBetModalProps> = ({ openModal, reward, tokenName }) => {
    const classes = useStyles()
    const theme = useTheme()
    const [open, setOpen] = useState(false)
    const won = Number(reward) > 0

    useEffect(() =>{
        if (openModal === true) {
            setOpen(true)
        }
        else{
            setOpen(false)
        }
    }, [openModal])

    const handleClose = () => {
        setOpen(false)
    };
    
    const body = (
      <div className={classes.paper}>
        {won ? (<Typography variant="h6" style={{color: theme.palette.primary.contrastText}}>Congratulations 🎉</Typography>) : 
               (<Typography variant="h6" style={{color: theme.palette.primary.contrastText}}>Next spin you will have more luck 🍀</Typography>)}
        {won === true &&
            <Typography variant="h6" style={{color: theme.palette.primary.contrastText}}>You won {getFullDisplayBalance(new BigNumber(reward))} {tokenName}, your balance has increased by {getFullDisplayBalance(new BigNumber(reward))} {tokenName}</Typography>
        }
        <p id="simple-modal-description">
          <div>
            <Button variant="contained" color="primary" onClick={handleClose}>
              Ok
            </Button>
          </div>
        </p>
      </div>
    );

    return (
        <Modal
          open={open}
          className={classes.modal}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description">
          {body}
        </Modal>
    )
}
export default ConfirmBetModal