import { useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import { Contract } from 'web3-eth-contract'
import useRefresh from './useRefresh'

export const getTokenBalance = async (userAddress: string | null, tokenContract: Contract ): Promise<string> => {
    try {
      const balance: string = await tokenContract.methods.balanceOf(userAddress).call()
      return balance
    } catch (e) {
      return '0'
    }
  }
  
export const useTokenBalance = (tokenContract: Contract) => {
    const [balance, setBalance] = useState(new BigNumber(0))
    const { account } = useWeb3React()
    const { fastRefresh } = useRefresh()
  
    useEffect(() => {
      const fetchBalance = async () => {
        const res = await getTokenBalance(account, tokenContract)
        setBalance(new BigNumber(res))
      }
  
      if (account) {
        fetchBalance()
      }
    }, [account, tokenContract, fastRefresh])
  
    return balance
  }