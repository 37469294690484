import React, { useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import { Contract } from 'web3-eth-contract'
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Modal from '@material-ui/core/Modal'
import Button from '@material-ui/core/Button'
import { useBetMoneyWheel, getGameById } from './hooks/useBetMoneyWheel'
import { getBalanceNumber } from './utils/formatBalance'

export interface MoneyWheelBet {
    val1: string
    val3: string
    val5: string
    val10: string
    val20: string
    val50: string
}

interface ConfirmBetModalProps{
    moneyWheelContract: Contract
    account: string
    bet: MoneyWheelBet
    tokenName: string
    onResult: (spin: number, reward: string) => void
    licensed: boolean
    disabled: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    button: {
      margin: theme.spacing(1),
      fontWeight: 'bold',
      borderRadius: 12,
      "&:hover": {
        backgroundColor: theme.palette.primary.main
      },
      "&:disabled":{
        opacity: 0.8
      }
    }
  }),
);

const ConfirmBetModal: React.FC<ConfirmBetModalProps> = ({ moneyWheelContract, account, bet, tokenName, onResult, licensed, disabled }) => {
    const classes = useStyles()
    const theme = useTheme()
    const [pendingTx, setPendingTx] = useState(false)
    const [open, setOpen] = useState(false)
    const [gameId, setGameId] = React.useState("")

    const { onBetMoneyWheel } = useBetMoneyWheel(moneyWheelContract)
    
    let totalBet = 0
    Object.entries(bet).forEach(([_, value]) => {
      totalBet += Number(value)
    })

    useEffect(()=> {
      // check if we have a running game in queue
      if (account && gameId === ""){
        const storedGameId = localStorage.getItem("gameId")
        if (storedGameId !== null && storedGameId !== "") {
          // check if lastGame is for account
          console.info("GameId found in localstorage")
          const getLastGameResult = async(gameId: string) => {
            const blockChainGame = await getGameById(moneyWheelContract, gameId)
            if (blockChainGame.account === account){
              console.info(blockChainGame)
              console.info("Loaded Game: ", blockChainGame)
              setGameId(storedGameId)

              if (Number(blockChainGame._1) !== 0)
                bet.val1 = getBalanceNumber(new BigNumber(blockChainGame._1)).toString()
              if (Number(blockChainGame._3) !== 0)
                bet.val3 = getBalanceNumber(new BigNumber(blockChainGame._3)).toString()
              if (Number(blockChainGame._5) !== 0)
                bet.val5 = getBalanceNumber(new BigNumber(blockChainGame._5)).toString()
              if (Number(blockChainGame._10) !== 0)
                bet.val10 = getBalanceNumber(new BigNumber(blockChainGame._10)).toString()
              if (Number(blockChainGame._20) !== 0)
                bet.val20 = getBalanceNumber(new BigNumber(blockChainGame._20)).toString()
              if (Number(blockChainGame._50) !== 0)
                bet.val50 = getBalanceNumber(new BigNumber(blockChainGame._50)).toString()

              if (!open) {
                setPendingTx(true)
                handleOpen()
              }
            }
          }
          getLastGameResult(storedGameId)
        }
      }

      if (gameId !== "") {
        const getLastGameResult = async() => {
          const lastGame = await getGameById(moneyWheelContract, gameId)
          
          if (lastGame.account === account) {
            if (lastGame.seed > 0 || lastGame.spin > 0) {
              
              handleClose()
              setPendingTx(false)
              setGameId("")
              localStorage.removeItem("gameId")

              onResult(lastGame.spin, lastGame.reward)
            } else {
              console.info("Waiting for result...")
            }
          }
        }

        getLastGameResult()
      }

    }, [gameId, bet, account, open, onResult, moneyWheelContract])
    
    const onConfirm = async () => {
      const gameId = await onBetMoneyWheel(bet)
      
      if (gameId !== null || gameId !== "") {
        
        console.info("=== gameId ===")
        console.info(gameId)

        setGameId(gameId)
        localStorage.setItem("gameId", gameId)
      }
      else {
        window.alert("Something went wrong... your funds has been refunded")
      }
    }

    const handleOpen = () => {
      setOpen(true)
    }
      
    const handleClose = () => {
      setOpen(false)
    }
    
    const body = (
      <div className={classes.paper}>
        <Typography variant="h6" style={{color: theme.palette.primary.contrastText}}>
          {!pendingTx ? "Are you sure you want to place your bet" : "Waiting for result" }
        </Typography>
        <Typography variant="h6" style={{color: theme.palette.primary.contrastText}}>
        Total bet: {totalBet} {tokenName}
        </Typography>
        <p id="simple-modal-description">
          <div>
            <Button color="secondary" variant="contained" className={classes.button} onClick={handleClose}>
              Cancel
            </Button>
            <Button
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={pendingTx}
                onClick={async () => {
                  setPendingTx(true)
                  handleOpen()
                  await onConfirm()
                }}>
                {pendingTx ? "Pending Confirmation" : "Confirm" }
            </Button>
          </div>
        </p>
      </div>
    );

    const renderActionButtons = () => {
      if (!licensed){
        return(
          <Button fullWidth disabled={true} className={classes.button} variant="contained" color="primary" >
            Unlicensed
          </Button>
        )
      }
      else {
        return (
          <>
            <Modal
              open={open}
              className={classes.modal}
              onClose={handleClose}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description">
              {body}
            </Modal>
            <Button fullWidth disabled={disabled || !licensed || pendingTx }  className={classes.button} variant="contained" color="primary" onClick={handleOpen}>
                {pendingTx ? "Waiting for result ..." : "Place Bet" }
            </Button>
          </>
        )
      }
    }

    return (<>{renderActionButtons()}</>)
}
export default ConfirmBetModal