import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';

interface BetInputProps
{
  onSelectMax?: (evt : any) => void
  onChange: (evt: any) => void
  name: string
  value: string
  multiplier: string
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      margin: 5,
      backgroundColor: 'transparent',
    },
    maxButton: {
      margin: 6,
      boxShadow: 'none',
      borderRadius: 10,
    },
    inputBox: {
      color: '#fff',
      width: '100%',
      height: 50,
      border: 'solid 1px #fff',
      borderRadius: 10,
    },
    multiplier: {
      flex: '0 0 auto',
      width: 65,
      margin: 0,
      height: 48,
      borderRadius: 10,
      fontSize: 20,
      fontWeight: 'bold',
      userSelect: 'none',
      marginRight: 10,
    }
  }),
);

const BetInput: React.FC<BetInputProps> = ({multiplier, onSelectMax, onChange, name, value}) => {
  const classes = useStyles()

  const MaxButton = () => (
    <Button name={name} variant="contained" color="primary" className={classes.maxButton} onClick={onSelectMax}>MAX</Button>
    )
  const MultiplierEl = () => {
    return(<Chip className={classes.multiplier} color="primary" label={multiplier}/>)
  }

  return (
    <>
    <Paper elevation={0} className={classes.root}>
      <div className={classes.inputBox}>
      <TextField
         fullWidth
         value={value}
         onChange={onChange}
         name={name}
         InputProps={{startAdornment: <MultiplierEl />, endAdornment: <MaxButton />, disableUnderline: true}}
       />
      </div>
    </Paper>
       </>
  );
}

export default React.memo(BetInput)