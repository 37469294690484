import { useCallback } from 'react'
import { Contract } from 'web3-eth-contract'
import { useWeb3React } from '@web3-react/core'
// import { useWallet } from '@binance-chain/bsc-use-wallet'

export const issue = async (tokenContract: Contract, account) => {
    return tokenContract.methods.issue().send({ from: account })
}

export const useRetrieveTestTokens = (tokenContract : Contract) => {
    const { account } = useWeb3React()
    const handleRetrieveTokens = useCallback(async () => {
      try {
        const tx = await issue(tokenContract, account)
        return tx
      } catch (e) {
        return false
      }
    }, [tokenContract, account])
  
    return { onRetrieveTestTokens: handleRetrieveTokens}
  }