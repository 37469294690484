import React, { useState, useEffect } from "react";
import { Contract } from 'web3-eth-contract'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import BigNumber from 'bignumber.js'
import BetInput from "./BetInput";
import BetAction from "./BetAction";
import { getFullDisplayBalance } from './utils/formatBalance'
import { useGetMinBet, useGetMaxBet } from './hooks/useBetMoneyWheel';

interface BetCardProps {
    account: string
    licensed: boolean
    tokenBalance: string
    tokenName: string
    onResult: (spin: number, reward: string) => void
    tokenContract: Contract
    moneyWheelContract: Contract
}

const initialBetState = {
    val1: '',
    val3: '',
    val5: '',
    val10: '',
    val20: '',
    val50: '',
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    error: {
      textAlign: 'center',
    },
    card: {
      display: 'flex',
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      borderRadius: 30,
      padding: 20,
    },
    cardContent: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'space-between',
      flexDirection: 'column',
      width: '100%',
    },
  }),
);

const BetCard: React.FC<BetCardProps> = ({ account, licensed, tokenBalance, tokenName, onResult, tokenContract, moneyWheelContract}) => {
    const classes = useStyles();
    const [bet, setBet] = useState(initialBetState)
    const [error, setError] = useState(false)
    const contractMinBet = getFullDisplayBalance(useGetMinBet(moneyWheelContract))
    const contractMaxBet = getFullDisplayBalance(useGetMaxBet(moneyWheelContract))

    useEffect(()=>{
        let totalBet = 0
        let newError = false

        Object.entries(bet).forEach(([_, value]) => {
          if (value){
            if (Number(value) < Number(contractMinBet.toString()) ||
                Number(value) > Number(contractMaxBet.toString())) {
              newError = true
            }
          }  
          totalBet += Number(value)
        })

        if (!newError) {
          if (totalBet < Number(contractMinBet.toString()) || 
              totalBet > Number(contractMaxBet.toString()) || 
              totalBet > Number(tokenBalance.toString())) {
            newError = true
          }
        }

        if (newError !== error)
          setError(newError)

    
        totalBet = 0
    }, [error, bet, contractMinBet, contractMaxBet, tokenBalance])

    const handleChange = (evt: any) => {      
      let newBet = {
        ...bet,
        [evt.target.name]: evt.target.value,
      }
      
      setBet(newBet)
    }

    const handleSelectMax = (evt : any) => {
        let totalBet = new BigNumber(tokenBalance).toString()

        if (Number(totalBet) > Number(contractMaxBet.toString()))
            totalBet = contractMaxBet.toString()
        
        let name = evt.target.name
        
        if (name === undefined)
            name = evt.target.parentNode.name

        setBet({
            ...initialBetState,
            [name]: totalBet,
        })
    }
  
    return(<>
            <Card className={classes.card}> 
            <CardContent className={classes.cardContent}>
            <Typography
            style={{ fontWeight: 600 }}
            gutterBottom
            variant="h4"
          >
            Pick your number
          </Typography>
            
        <Grid container spacing={2}>
            <Grid item md={6} sm={12} xs={12}> 
              <BetInput multiplier="1" onChange={handleChange} onSelectMax={handleSelectMax} value={bet.val1} name="val1"/>
            </Grid>
            <Grid item md={6} sm={12} xs={12}> 
              <BetInput multiplier="10" onChange={handleChange} onSelectMax={handleSelectMax} value={bet.val10} name="val10"/>
            </Grid>
            <Grid item md={6} sm={12} xs={12}> 
              <BetInput multiplier="3" onChange={handleChange} onSelectMax={handleSelectMax} value={bet.val3} name="val3"/>    
            </Grid>
            <Grid item md={6} sm={12} xs={12}> 
              <BetInput multiplier="20" onChange={handleChange} onSelectMax={handleSelectMax} value={bet.val20} name="val20"/>
            </Grid>
            <Grid item md={6} sm={12} xs={12}> 
              <BetInput multiplier="5" onChange={handleChange} onSelectMax={handleSelectMax} value={bet.val5} name="val5"/>  
            </Grid>
            <Grid item md={6} sm={12} xs={12}> 
              <BetInput multiplier="50" onChange={handleChange} onSelectMax={handleSelectMax} value={bet.val50} name="val50"/>
            </Grid>
            <Grid item xs={12}> 
              <BetAction account={account} licensed={licensed} disabled={error} bet={bet} tokenName={tokenName} onResult={onResult} tokenContract={tokenContract} moneyWheelContract={moneyWheelContract} />            
            </Grid>
            <Grid item xs={12}> 
            {error ? <Typography
            className={classes.error}
            
            gutterBottom
          >
            Minimal bet size should be: <b>{contractMinBet}</b> and maximum bet size should be: <b>{contractMaxBet}</b>
          </Typography>: <div></div>}
            </Grid>
        </Grid>

          </CardContent>
        </Card>
    </>)
}

export default BetCard;