import { InjectedConnector } from '@web3-react/injected-connector'
// import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
// import { BscConnector } from '@binance-chain/bsc-connector'
import Web3 from 'web3'
// import getNodeUrl from './getRpcUrl'

enum ConnectorNames {
    Injected = "injected",
    // WalletConnect = "walletconnect",
    // BSC = "bsc"
}

// const POLLING_INTERVAL = 12000
// const rpcUrl = getNodeUrl()
const chainId = 97

const injected = new InjectedConnector({ supportedChainIds: [chainId] })

// const walletconnect = new WalletConnectConnector({
//   rpc: { [chainId]: rpcUrl },
//   bridge: 'https://bridge.walletconnect.org',
//   qrcode: true,
//   pollingInterval: POLLING_INTERVAL,
// })

// const bscConnector = new BscConnector({ supportedChainIds: [chainId] })

export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.Injected]: injected,
  // [ConnectorNames.WalletConnect]: walletconnect,
  // [ConnectorNames.BSC]: bscConnector,
}

export const getLibrary = (provider): Web3 => {
  return provider
}
