import { useCallback } from 'react'
import { Contract } from 'web3-eth-contract'
// import { useWallet } from '@binance-chain/bsc-use-wallet'
import { useWeb3React } from '@web3-react/core'

import { ethers } from 'ethers'

export const approve = async (lpContract : Contract, masterChefContract: Contract, account) => {
  return lpContract.methods
    .approve(masterChefContract.options.address, ethers.constants.MaxUint256)
    .send({ from: account })
}

export const useMoneyWheelApprove = (tokenContract : Contract, moneywheelContract: Contract) => {
  const { account } = useWeb3React()

  const handleApprove = useCallback(async () => {
    try {
      const tx = await approve(tokenContract, moneywheelContract, account)
      return tx
    } catch (e) {
      return false
    }
  }, [account, tokenContract, moneywheelContract])

  return { onApprove: handleApprove }
}