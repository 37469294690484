import { useCallback, useEffect, useState } from 'react'
import { Contract } from 'web3-eth-contract'
import BigNumber from 'bignumber.js'
//import { useWallet } from '@binance-chain/bsc-use-wallet'
import { useWeb3React } from '@web3-react/core'
export interface MoneyWheelBet {
  val1: string
  val3: string
  val5: string
  val10: string
  val20: string
  val50: string
}

export const placeBet = async (moneyWheelContract : Contract, bet: MoneyWheelBet, account) => {
  const val1 = new BigNumber(bet.val1).multipliedBy(new BigNumber(10).pow(18))
  const val3 = new BigNumber(bet.val3).multipliedBy(new BigNumber(10).pow(18))
  const val5 = new BigNumber(bet.val5).multipliedBy(new BigNumber(10).pow(18))
  const val10 = new BigNumber(bet.val10).multipliedBy(new BigNumber(10).pow(18))
  const val20 = new BigNumber(bet.val20).multipliedBy(new BigNumber(10).pow(18))
  const val50 = new BigNumber(bet.val50).multipliedBy(new BigNumber(10).pow(18))

  return moneyWheelContract.methods
    .bet(
      val1.isNaN() ? '0' : val1.toString(),
      val3.isNaN() ? '0' : val3.toString(),
      val5.isNaN() ? '0' : val5.toString(),
      val10.isNaN() ? '0' : val10.toString(),
      val20.isNaN() ? '0' : val20.toString(),
      val50.isNaN() ? '0' : val50.toString(),
    )
    .send({ 
      from: account,
      value: await getRandomCharge(moneyWheelContract),
    })
    .on('transactionHash', (tx: any) => {
      return tx.transactionHash
    })
}

export const getLastResult = async (moneyWheelContract: Contract, account) => {
  return moneyWheelContract.methods.lastResult(account).call()
}

export const getGameById = async (moneyWheelContract: Contract, account) => {
  return moneyWheelContract.methods.games(account).call()
}


export const useBetMoneyWheel = (moneyWheelContract: Contract) => {
  const { account } = useWeb3React()

  const handleBet = useCallback(
    async (bet: MoneyWheelBet) => {
      
      const txHash = await placeBet(moneyWheelContract, bet, account)
      console.info(txHash)

      const gameId = txHash.events.GameRequest.returnValues.gameId
          
      return gameId

    },
    [account, moneyWheelContract],
  )

  return { onBetMoneyWheel: handleBet }
}


export const getRandomCharge = async (moneyWheelContract: Contract) => {
  return await moneyWheelContract.methods.randomCharge().call()
}

export const getMinBetValue = async (moneyWheelContract: Contract) => {
  return await moneyWheelContract.methods.minBet().call()
}

export const useGetMinBet = (moneyWheelContract: Contract) => {
  const [minBet, setMinBet] = useState(new BigNumber(0))
  const { account } = useWeb3React()

  useEffect(() => {
    if (account && moneyWheelContract) {
      const fetchMinBet = async () => {
        const state = await getMinBetValue(moneyWheelContract)
        setMinBet(new BigNumber(state))
      }

      fetchMinBet()
    }
  }, [account, moneyWheelContract])

  return minBet
}

export const getMaxBetValue = async (moneyWheelContract: Contract) => {
  return await moneyWheelContract.methods.maxBet().call()
}

export const useGetMaxBet = (moneyWheelContract: Contract) => {
  const [maxBet, setMaxBet] = useState(new BigNumber(0))
  const { account } = useWeb3React()

  useEffect(() => {
    if (account && moneyWheelContract) {
      const fetchMaxBet = async () => {
        const state = await getMaxBetValue(moneyWheelContract)
        setMaxBet(new BigNumber(state))
      }

      fetchMaxBet()
    }
  }, [account, moneyWheelContract])

  return maxBet
}

