import { useEffect, useState } from 'react'
import { Contract } from 'web3-eth-contract'
import BigNumber from 'bignumber.js'
// import { useWallet } from '@binance-chain/bsc-use-wallet'
import { useWeb3React } from '@web3-react/core'

const getAllowance = async (
  lpContract: Contract,
  masterChefContract: Contract,
  account: string,
): Promise<string> => {
  try {
    const allowance: string = await lpContract.methods.allowance(account, masterChefContract.options.address).call()
    return allowance
  } catch (e) {
    return '0'
  }
}

export const useMoneyWheelAllowance = (tokenContract: Contract, moneyWheelContract: Contract) => {
    const [allowance, setAllowance] = useState(new BigNumber(0))
    const { account } = useWeb3React()

    useEffect(() => {
      const fetchAllowance = async () => {
        const res = await getAllowance(tokenContract,moneyWheelContract,account)
        //await tokenContract.methods.allowance(account, moneyWheelContract.options.address).call()
        setAllowance(new BigNumber(res))
      }
  
      if (account && tokenContract && moneyWheelContract) {
        fetchAllowance()
      }
      const refreshInterval = setInterval(fetchAllowance, 10000)
      return () => clearInterval(refreshInterval)
    }, [account, tokenContract, moneyWheelContract])
  
    return allowance
  }