import React, {useState, useEffect } from 'react'
import { Contract } from 'web3-eth-contract'
import ScopedCssBaseline from '@material-ui/core/ScopedCssBaseline'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import MoneyWheelApp from './MoneyWheelApp'
//import isLicensed from 'license/src/index'
import { isLicenced } from './utils/license'

interface Colors{
    backgroundColor: string
    primaryColor: string
    primaryContrastTextColor: string
    secondaryColor: string
    secondaryContrastTextColor: string
    cardColor: string
    wheelPrimaryColor: string
    wheelSecondaryColor: string    
    jackpotColor: string
}

interface moneyWheelBscProps {
    contract: Contract
    token: Contract
    account: string
    tokenName: string
    tokenBalance: string
    colors: Colors
    tokenImageUrl: string
    soundUrl: string
    wheelBackgroundImageUrl?: string
    demo?: boolean
}

const MoneyWheelBsc: React.FC<moneyWheelBscProps> = ({contract, token, account, tokenName, tokenBalance, colors, tokenImageUrl, soundUrl, wheelBackgroundImageUrl, demo}) => {
  const [license, setLicense] = useState(false)  

  const theme = createMuiTheme({
        palette: {
          primary: {
            main: colors.primaryColor,
            contrastText: colors.primaryContrastTextColor
          },
          secondary: {
            main: colors.secondaryColor,
            contrastText: colors.secondaryContrastTextColor
          },
          text: {
            primary: colors.primaryContrastTextColor,
            secondary: colors.secondaryContrastTextColor,
          },
          background: {
            paper: colors.cardColor
          },
        },
        overrides: {
          MuiButton: {
            label: {
              color: colors.primaryContrastTextColor
            },
          },
        }
    });

    useEffect(()=>{
      isLicenced('wheel').then((r: any) =>setLicense(r))
    })

    return (
      <ScopedCssBaseline>
        <MuiThemeProvider theme={theme}>
          <div style={{backgroundColor: colors.backgroundColor}}>
            <MoneyWheelApp
              licensed={license}
              account={account}
              contract={contract} 
              token={token} 
              tokenName={tokenName} 
              tokenBalance={tokenBalance} 
              tokenImageUrl={tokenImageUrl} 
              soundUrl={soundUrl}
              wheelPrimaryColor={colors.wheelPrimaryColor}
              wheelSecondaryColor={colors.wheelSecondaryColor}
              jackpotColor={colors.jackpotColor}
              wheelBackgroundImageUrl={wheelBackgroundImageUrl} 
              demo={demo}/>
          </div>
        </MuiThemeProvider>
      </ScopedCssBaseline>
    )
}

export default MoneyWheelBsc