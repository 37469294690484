import React, {useState, useEffect} from "react";
import Sound from 'react-sound';
import { Wheel } from 'react-custom-roulette'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, useTheme, Theme } from '@material-ui/core/styles';


interface MoneyWheelProps {
  licensed: boolean
  prizeNumber: number
  mustSpin: boolean
  onStopping: () => void
  soundUrl: string
  wheelPrimaryColor: string
  wheelSecondaryColor: string    
  jackpotColor: string
  backgroundImage?: string
}

interface IStyleProps {
  backgroundImage: string
}

const useStyles = makeStyles<Theme, IStyleProps>((theme: Theme) =>
  createStyles({
    wheel: 
    {
      display: 'flex',
      justifyContent: 'center',
    },
    card: props=>({
      display: 'flex',
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      borderRadius: 30,
      padding: 20,
      position: 'relative',
      zIndex: 0,
      "&:after": {
        content: '""',
        backgroundImage: `url(${props.backgroundImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        opacity: '0.75',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'absolute',
        zIndex: -1,
      },
    }),
    cardContent: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'space-between',
      flexDirection: 'column',
      width: '100%',
    },
    license: {
      color: '#fff',
      textAlign: 'center',
      fontWeight: 'bold',
      paddingTop: theme.spacing(1),
    },
    }),
);

const MoneyWheel: React.FC<MoneyWheelProps> = ({ licensed, prizeNumber, mustSpin, onStopping, soundUrl, wheelPrimaryColor, wheelSecondaryColor, jackpotColor, backgroundImage }) => {
    const styleProps: IStyleProps = { backgroundImage: backgroundImage}
    const theme = useTheme()
    const classes = useStyles(styleProps)
    
    const [playSound, setPlaySound] = useState(Sound.status.STOPPED)
    const [volumeOn, setVolumeOn] = useState(true)
    

    const data = [
      { option: '0', style: { backgroundColor: wheelPrimaryColor } },
      { option: '1', style: { backgroundColor: wheelSecondaryColor }  },
      { option: '3', style: { backgroundColor: wheelPrimaryColor }  },
      { option: '1', style: { backgroundColor: wheelSecondaryColor }  },
      { option: '5', style: { backgroundColor: wheelPrimaryColor }  },
    
      { option: '1', style: { backgroundColor: wheelPrimaryColor }  },
      { option: '3', style: { backgroundColor: wheelSecondaryColor }  },
      { option: '1', style: { backgroundColor: wheelPrimaryColor }  },
      { option: '10', style: { backgroundColor: wheelSecondaryColor }  },
      { option: '3', style: { backgroundColor: wheelPrimaryColor }  },
    
      { option: '1', style: { backgroundColor: wheelSecondaryColor }  },
      { option: '5', style: { backgroundColor: wheelPrimaryColor }  },
      { option: '1', style: { backgroundColor: wheelSecondaryColor }  },
      { option: '20', style: { backgroundColor: wheelPrimaryColor }  },
      { option: '1', style: { backgroundColor: wheelSecondaryColor }  },
      
      { option: '3', style: { backgroundColor: wheelPrimaryColor }  },
      { option: '1', style: { backgroundColor: wheelSecondaryColor }  },
      { option: '3', style: { backgroundColor: wheelPrimaryColor }  },
      { option: '1', style: { backgroundColor: wheelSecondaryColor }  },
      { option: '5', style: { backgroundColor: wheelPrimaryColor }  },
    
      { option: '1', style: { backgroundColor: wheelSecondaryColor }  },
      { option: '10', style: { backgroundColor: wheelPrimaryColor }  },
      { option: '1', style: { backgroundColor: wheelSecondaryColor }  },
      { option: '3', style: { backgroundColor: wheelPrimaryColor }  },
      { option: '5', style: { backgroundColor: wheelSecondaryColor }  },
    
      { option: '1', style: { backgroundColor: wheelPrimaryColor }  },
      { option: '50', style: { backgroundColor: jackpotColor }  }, // 
      { option: '1', style: { backgroundColor: wheelPrimaryColor }  },
      { option: '3', style: { backgroundColor: wheelSecondaryColor }  },
      { option: '1', style: { backgroundColor: wheelPrimaryColor }  },
    
      { option: '5', style: { backgroundColor: wheelSecondaryColor }  },
      { option: '1', style: { backgroundColor: wheelPrimaryColor }  },
      { option: '3', style: { backgroundColor: wheelSecondaryColor }  },
      { option: '1', style: { backgroundColor: wheelPrimaryColor }  },
      { option: '10', style: { backgroundColor: wheelSecondaryColor }  },
    
      { option: '5', style: { backgroundColor: wheelPrimaryColor }  },
      { option: '1', style: { backgroundColor: wheelSecondaryColor }  },
      { option: '3', style: { backgroundColor: wheelPrimaryColor }  },
      { option: '1', style: { backgroundColor: wheelSecondaryColor }  },
      { option: '20', style: { backgroundColor: wheelPrimaryColor }  },
    
      { option: '1', style: { backgroundColor: wheelSecondaryColor }  },
      { option: '3', style: { backgroundColor: wheelPrimaryColor }  },
      { option: '1', style: { backgroundColor: wheelSecondaryColor }  },
      { option: '5', style: { backgroundColor: wheelPrimaryColor }  },
      { option: '1', style: { backgroundColor: wheelSecondaryColor }  },
    
      { option: '3', style: { backgroundColor: wheelPrimaryColor }  },
      { option: '1', style: { backgroundColor: wheelSecondaryColor }  },
      { option: '5', style: { backgroundColor: wheelPrimaryColor }  },
      { option: '1', style: { backgroundColor: wheelSecondaryColor }  },
      { option: '10', style: { backgroundColor: wheelPrimaryColor }  },
      { option: '3', style: { backgroundColor: wheelSecondaryColor }  },
      { option: '1', style: { backgroundColor: wheelPrimaryColor }  },
    ]
   
    useEffect(()=>{
      if (mustSpin && volumeOn){
        setPlaySound(Sound.status.PLAYING)
      }
      else{
        setPlaySound(Sound.status.STOPPED)
      }
    }, [mustSpin, volumeOn])

    const setVolume = () => {
      setVolumeOn(!volumeOn)
    }

    return (
      <Card className={classes.card}> 
        <CardContent className={classes.cardContent}>
          <div style={{ display: 'flex' }}>
            <Typography
            style={{ fontWeight: 600, flex: 1 }}
            gutterBottom
            variant="h4">
              Money Wheel
            </Typography>                            
            {volumeOn ?
              <VolumeUpIcon onClick={setVolume} fontSize="large" /> : 
              <VolumeOffIcon onClick={setVolume} fontSize="large" /> 
            }
          </div>

            <div className={classes.wheel}>
              <Wheel
              mustStartSpinning={mustSpin}
              prizeNumber={prizeNumber}
              data={data}
              textColors={[theme.palette.primary.contrastText]}
              outerBorderColor={'transparent'}
              innerBorderColor={'transparent'}
              radiusLineColor={'grey'}
              innerBorderWidth={20}
              outerBorderWidth={10}
              innerRadius={25}
              radiusLineWidth={1}
              perpendicularText={true}
              textDistance={80}
              onStopSpinning={onStopping}/>
              <Sound
                url={soundUrl}
                playStatus={playSound}
              />
            </div>
            {licensed ?
              <a href="https://defifusion.io" target="_blank" rel="noreferrer" className={classes.license} style={{color: theme.palette.primary.contrastText}}>Licensed by DeFiFusion</a> :
              <span className={classes.license} style={{color: theme.palette.primary.contrastText}}>
                <strong>UNLICENSED</strong><br />
                <a href="https://defifusion.io" target="_blank" rel="noreferrer" className={classes.license} style={{color: theme.palette.primary.contrastText}}>Please contact DeFiFusion</a>
              </span>
            }
        </CardContent>
      </Card>
    )
}

export default MoneyWheel