import React, { useState } from "react"
import { Contract } from 'web3-eth-contract'
import Grid from '@material-ui/core/Grid'
import MoneyWheel from './MoneyWheel'
import BalanceCard from './BalanceCard'
import BetCard from './BetCard'
import ResultModal from './ResultModal'

interface App {
    contract: Contract
    token: Contract
    account: string
    tokenBalance: string
    tokenName: string
    tokenImageUrl: string
    soundUrl: string
    wheelPrimaryColor: string
    wheelSecondaryColor: string    
    jackpotColor: string
    wheelBackgroundImageUrl?: string
    licensed: boolean
    demo?: boolean
}

  const MoneyWheelApp: React.FC<App> = ({ contract, token, account, tokenName, tokenBalance, tokenImageUrl, soundUrl, wheelPrimaryColor, wheelSecondaryColor, jackpotColor, wheelBackgroundImageUrl, licensed, demo}) => {
    const [mustSpin, setMustSpin] = useState(false)
    const [prizeNumber, setPrizeNumber] = useState(0)
    const [reward, setReward] = useState("")
    const [openResultModal, setOpenResultModal] = useState(false)

    const onResult = (spin: number, reward: string) => {
      setPrizeNumber(spin)
      setReward(reward)
      setOpenResultModal(false)
      setMustSpin(true)

      // console.info("=== Result event ===")
      // console.info(event)

      // if (event.length > 0) {
      //   setPrizeNumber(event[0].returnValues.spin)
      //   setReward(event[0].returnValues.reward)
      //   setOpenResultModal(false)
      //   setMustSpin(true)  
      // }
    }
    
    const sleep = (milliseconds: number) => {
      return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    const onStopping = () => {
      setMustSpin(false)
      sleep(3000).then(() => {
        setOpenResultModal(true)
      }) 
    }

    return(
      <div style={{ flexGrow: 1 }} >
        <Grid container alignItems="stretch" spacing={4}>
          <Grid item style={{display: 'flex'}} xs={12} md={12} lg={6}>
            <MoneyWheel
              licensed={licensed}
              prizeNumber={prizeNumber} 
              mustSpin={mustSpin} 
              onStopping={onStopping} 
              soundUrl={soundUrl} 
              wheelPrimaryColor={wheelPrimaryColor} 
              wheelSecondaryColor={wheelSecondaryColor} 
              jackpotColor={jackpotColor} 
              backgroundImage={wheelBackgroundImageUrl} />
          </Grid>
          <Grid item style={{display: 'flex'}} xs={12} md={12} lg={6}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={12} lg={12}>
                <BalanceCard tokenBalance={tokenBalance} tokenImageUrl={tokenImageUrl} tokenContract={token} onSpinning={mustSpin} demo={demo}/>
              </Grid>
              <Grid item style={{display: 'flex'}} xs={12} md={12}>
                <BetCard
                  account={account}
                  licensed={licensed}
                  tokenBalance={tokenBalance} 
                  tokenName={tokenName} 
                  onResult={onResult} 
                  tokenContract={token} 
                  moneyWheelContract={contract}/>
            </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ResultModal openModal={openResultModal} reward={reward} tokenName={tokenName} />
      </div>
    )
  }

  export default MoneyWheelApp