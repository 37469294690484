import React, {useState, useCallback } from 'react'
import { Contract } from 'web3-eth-contract'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import { useRetrieveTestTokens } from './hooks/useRetrieveTestTokens'
import { Avatar, Grid } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      justifyContent: 'space-between',
    },
    card: {
      display: 'flex',
      justifyConten: 'space-between',
      flexDirection: 'column',
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      borderRadius: 30,
      padding: 20,
    },
    avatar: {
      width: 185,
      height: 155,
    }
  }),
);

interface balanceCardProps{
  tokenBalance: string
  tokenImageUrl: string
  tokenContract: Contract
  onSpinning: boolean
  demo?: boolean
}

const BalanceCard: React.FC<balanceCardProps> = ({ tokenBalance, tokenImageUrl, tokenContract, onSpinning, demo}) => {
  const classes = useStyles();
  const [requestedTokens, setRequestedTokens] = useState(false)
  const { onRetrieveTestTokens } = useRetrieveTestTokens(tokenContract)

  const handleRetrieveTokens = useCallback(async () => {
    try 
    {
      setRequestedTokens(true)
      await onRetrieveTestTokens()
      setRequestedTokens(false)
    } catch (e) {
      console.error(e)
    }
  }, [onRetrieveTestTokens])

  return (
    <Card className={classes.card}> 
    <CardContent>
      <Grid
        className={classes.root}
        container
        spacing={3}>
        <Grid item>
          <Typography
            style={{ fontWeight: 600 }}
            gutterBottom
            variant="h4"
          >
            Total Balance
          </Typography>
          <Typography
            
            variant="h4"
          >
          {onSpinning === false &&
            tokenBalance
          }
          </Typography>
          <br/>
          {demo === true &&
            <Button variant="outlined" color="primary" disabled={requestedTokens} onClick={handleRetrieveTokens}>Retrieve Test Tokens</Button>
          }
        </Grid>
        <Grid item>
          <Avatar variant="square" className={classes.avatar} src={tokenImageUrl}>
            <AttachMoneyIcon />
          </Avatar>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
  )
}

export default BalanceCard