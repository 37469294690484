import axios from 'axios'
const licenseServer = 'https://license.defifusion.io/'


export const isLicenced = async (game: any) => {
  let response
  try {
      response = await axios.get(licenseServer+window.location.hostname)
  } catch(e) {
      console.error('DeFiFusion: ', 'No license found for: '+window.location.hostname)
      return false
  }

  if (response.status === 200) {
      if (response.data.includes(game)) {
          return true
      }
  }

  console.error('DeFiFusion: ', 'No license found for: '+window.location.hostname)
  return false
}